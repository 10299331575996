/*=========================================================================================
	File Name: search.scss
	Description: Search  functionality.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy React Admin Template
	Version: 1.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

.navbar-container {
  a.nav-link-search {
    float: left;
  }
  .search-input {
    float: left;
    // padding-top: 1.2rem;
    width: 0;
    // select{
    input {
      width: 0;
      border: none;
      transition: all 0.2s ease-out;
      line-height: 16px;
      padding: 1.75rem 3.6rem;
      &:focus {
        outline: 0 !important;
        outline-offset: 0 !important;
      }
    }
    &.open {
      position: absolute;
      left: 0;
      // padding: 1.6rem;
      // background: #fff;
      right: 0;
      width: 100%;
      z-index: 1;
      border-radius: 0.5rem;
      .search-input-close,
      .search-input-icon {
        display: block;
      }
      // select{
      input {
        width: 100%;
        // padding-right:2rem;
        outline: none;
        transition: all 0.3s ease-out;
      }
    }
    .search-input-icon {
      z-index: 2;
      display: none;
      position: absolute;
      left: 1.5rem;
      top: 1.75rem;
      cursor: pointer;
    }
    .search-input-close {
      z-index: 1;
      display: none;
      position: absolute;
      right: 2rem;
      top: 20px;
      cursor: pointer;
    }
    .search-header {
      padding: 1.2rem 1.5rem;
      padding-bottom: 0.5rem;
    }
    .other-results {
      overflow: hidden;
      .permanent-result {
        width: 100%;
        cursor: pointer;
        padding: 0.5rem;
        padding-left: 1rem;
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }

  .bookmark-input {
    position: absolute;
    top: 102%;
    width: 25%;
    display: none;
    &.show {
      display: block;
    }
    .bookmark-input-icon {
      position: absolute;
      z-index: 1;
      top: 0.75rem;
      left: 1rem;
    }
    input {
      padding: 0.7rem 0.7rem 0.7rem 2.5rem;
      width: 100%;
      background: $white;
      border: 1px solid rgba($pure_black, 0.2);
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      &:focus {
        border: 1px solid $primary;
      }
    }

  }
  .bookmark-icon {
    cursor: pointer;
  }
  .dropdown.bookmark-dropdown {
    padding: 1.4rem 0.5rem 1.35rem;
    .dropdown-toggle:after {
      display: none;
    }
  }
}
/* .navbar-light, .navbar-semi-dark{
	.search-input{
		.input{
			color: $gray-600;
		}
		&.open{
			.input{
				color: $gray-600;
				// border-bottom: 1px solid $gray-600;

			}
		}
	}
} */
.navbar-dark,
.navbar-semi-light {
  .search-input {
    .input {
      color: #f5f7fa;
      border-radius: 0.5rem;
    }
    &.open {
      .input {
        color: #f5f7fa;
        border: 1px solid #f5f7fa;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #navbar-mobile {
    .search-input.open {
      .input {
        color: $gray-600;
      }
    }
  }
}


@media (max-width : 1300px){
  .navbar-container{
    .bookmark-input{
      width: 35%;
    }
  }
}