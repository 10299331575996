@import "../node_modules/prismjs/themes/prism-tomorrow";
@import "./assets/scss/app.scss";
@import "../node_modules/flatpickr/dist/flatpickr.min.css";

.mainTable{
    padding: 20px 0;
}
.gitIcon, .skypeIcon{
    display: flex;
    align-items: center;
    color: #536DFE;
}