/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy React Admin Template
  Version: 1.0
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
  ----------------------------------------------------------------------------------------

  Modifiers:       `progress-lg`
                   `progress-md`
                   `progress-sm`
                   `progress-xs`

==========================================================================================*/

// Size Modifier
// ========================================================================

.progress {
  margin-bottom: 3rem;

  // margin for last progress bar

  &:last-child {
    margin-bottom: 1rem;
  }

  // progress bar sizes

  &.progress-xl {
    height: $progress-size-xl;
  }

  &.progress-lg {
    height: $progress-size-lg;
  }

  &.progress-md {
    height: $progress-size-md;
  }

  &.progress-sm {
    height: $progress-size-sm;
  }

  // progress bar radius

  .progress-bar {
    border-radius: $progress-border-radius;
    &.progress-bar-animated {
      animation: progressBarAnimated 1s linear infinite;
    }
  }
  .progress-bar-striped {
    background-size: 1rem 1rem;
  }
}

[dir="rtl"] {
  .progress {
    .progress-bar.progress-bar-animated {
      animation: progressBarAnimatedRTL 1s linear infinite !important;
    }
  }
}

@keyframes progressBarAnimated {
  0% {
    background-position: 1rem 0;
  }

  100% {
    background-position: 0 0;
  }
}
/* rtl:ignore */
@keyframes progressBarAnimatedRTL {
  0% {
    background-position: 1rem 0;
  }

  100% {
    background-position: 0 100%;
  }
}
