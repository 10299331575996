// Popovers

// popover header color and border-radius
.popover {
  z-index: 1060;
  .popover-header {
    color: $white;
    border: none;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  &.bs-popover-bottom {
    .arrow:after {
      border-bottom-color: $primary;
    }
  }
}
